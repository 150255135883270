var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7abc73f44a791e7c0220fdcb2c76edb6e7f2608f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
export const ENVIRONMENT =
  process.env.NEXT_PUBLIC_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT

Sentry.init({
  environment: ENVIRONMENT,
  dsn:
    SENTRY_DSN ||
    'https://c2e0055aa99c4ea3857d5a886bc148cc@o411599.ingest.sentry.io/5287072',
  sampleRate: 0.1, // Drops 9/10 of requests
  tracesSampleRate: 0.2,
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null
    return event
  },
  ignoreErrors: [
    // https://sentry.io/organizations/momenthouse/issues/3214631689/?project=5287072
    'Request failed with status code 404',
    /NetworkError/,
    /Network Error/,
    /TypeError: cancelled/,
    /Window closed/,
    /Request aborted/,
    /Non-Error promise rejection captured with value/i,
  ],
})
